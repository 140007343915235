@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.v-simple-checkbox .v-icon {
  color: $black;
}
.stores-table {
  width: 100%;

  ::v-deep .v-input--selection-controls__input {
    margin-right: 0 !important;
  }

  @include media('xs-only') {
    ::v-deep tr {
      th:first-child,
      td:first-child {
        padding-left: 0;
      }
    }
  }
}
