@import "@/styles/config.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.categories__title {
  flex-shrink: 0;
}
.channels__title,
.categories__title {
  @include media('xs-only') {
    font-size: 1rem;
    font-weight: bold;
  }
}
.chip-fade {
  opacity: 0.7;
}
.chip-active {
  opacity: 1;
}
